import { Swiper, SwiperSlide } from 'swiper/react';
import { AppCard, AppKey } from 'src/components';
import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import rf from 'src/requests/RequestFactory';
import { useHistory } from 'react-router-dom';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

export default () => {
  const [data, setData] = useState<any[]>([]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const history = useHistory();

  const getTopKeys = async () => {
    try {
      const res = await rf
        .getRequest('AnalyticRequest')
        .getKeysLastActive();
      setData(res);
    } catch (e) {
      setData([]);
    }
  };

  useEffect(() => {
    getTopKeys().then();
  }, []);

  return (
    <Box mb={!!data?.length ? 5 : 0} position={'relative'}>
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        onBeforeInit={(swiper: any) => {
          swiper.prevEl = navigationPrevRef.current;
          swiper.nextEl = navigationNextRef.current;
        }}
        breakpoints={{
          320: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          560: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        modules={[Autoplay, Navigation]}
      >
        {data.map((item: any, index) => {
          return (
            <SwiperSlide key={index}>
              <AppKey data={item} />
            </SwiperSlide>
          );
        })}
        {!!data?.length && (
          <SwiperSlide>
            <AppCard minH={'250px'}>
              <Flex justifyContent={'center'} alignItems={'center'} h={'210px'}>
                <Box
                  fontSize={'22px'}
                  fontWeight={700}
                  onClick={() => history.push('/all-keys')}
                  cursor={'pointer'}
                >
                  See More
                </Box>
              </Flex>
            </AppCard>
          </SwiperSlide>
        )}
      </Swiper>
      <>
        <div
          ref={navigationPrevRef}
          className={!!data.length ? 'button-prev' : ''}
        />
        <div
          ref={navigationNextRef}
          className={!!data.length ? 'button-next' : ''}
        />
      </>
    </Box>
  );
};
