import { createSlice } from '@reduxjs/toolkit';
import Storage from 'src/utils/storage';
import {
  setAuthorizationToRequest,
  TIME_EXPIRE_TOKEN_CLIENT,
} from 'src/utils/utils-auth';

export type UserAuthType = {
  accessToken: string;
};

interface MyAccountState {
  auth: UserAuthType;
}

const initialState: MyAccountState = {
  auth: {
    accessToken: Storage.getAccessToken() || '',
  },
};

export const myAccount = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    setUserAuth: (
      state: { auth: { accessToken: string } },
      action: { payload: { accessToken: string } },
    ) => {
      const { accessToken } = action.payload;
      state.auth = { accessToken };
      const timeExpireToken = new Date().getTime() + TIME_EXPIRE_TOKEN_CLIENT;
      setAuthorizationToRequest(accessToken);
      Storage.setAccessToken(accessToken, timeExpireToken);
    },

    clearUser: () => {
      Storage.clearAccessToken();
      return initialState;
    },
  },
});

export const { clearUser } = myAccount.actions;
export default myAccount.reducer;
