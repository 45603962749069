import { AppCard } from './index';
import { formatCommaNumber, formatToPercent } from 'src/utils/utils-helper';
import { DownIcon, UpIcon } from 'src/assets/icons';
import { Box, Flex, Avatar } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import { Line, LineChart, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

interface IDataKey {
  holders?: number | string;
  total_supply?: number | string;
  usd_price?: number | string;
}

const AppKey = ({ data }: any) => {
  const [dataKey, setDataKey] = useState<IDataKey>({});
  const [dataChart, setDataChart] = useState<any[]>([]);
  const [dataPrice24h, setDataPrice24h] = useState<any[]>([]);
  const history = useHistory();

  const getInfoKey = async () => {
    try {
      const res = await rf.getRequest('NewBitcoinRequest').getKeysInfo({
        page: 1,
        limit: 1,
        key_type: 1,
        search: data?.address,
      });

      if (res.result && res.result.length) {
        setDataKey(res?.result[0]);
      } else {
        setDataKey({});
      }
    } catch (e) {
      setDataKey({});
    }
  };

  const getChartData = async () => {
    try {
      const res = await rf.getRequest('NewBitcoinRequest').getChartDataKey({
        address: data?.address,
        day: 7,
      });
      if (res.result && res.result.length) {
        const data24h = res.result.filter(
          (item: any) =>
            moment(item?.trade_time).valueOf() >=
            moment().utc().subtract(1, 'day').startOf('day').valueOf(),
        );
        setDataChart(res?.result);
        setDataPrice24h(data24h);
      } else {
        setDataChart([]);
      }
    } catch (e) {
      setDataChart([]);
    }
  };

  useEffect(() => {
    getInfoKey().then();
    getChartData().then();
  }, [data]);

  const priceLast24h = useMemo(() => dataPrice24h[0]?.price, [dataPrice24h]);
  const priceLast7d = useMemo(() => dataChart[0]?.price, [dataChart]);
  const priceCurrent = useMemo(
    () => dataChart[dataChart?.length - 1]?.price,
    [dataChart],
  );

  const getPercentChange = (min: number, max: number) => {
    if (!max) return '0%';
    if (!min) return '+100%';
    const change = Math.abs(max - min);
    return `${formatToPercent(change / min)}`;
  };

  return (
    <AppCard
      className="key"
      onClick={() => history.push(`/key/${data?.address}`)}
      cursor={'pointer'}
    >
      <div style={{ display: 'flex', gap: '10px' }}>
        <div>
          <Avatar
            w={'50px'}
            name={data?.twitter_name}
            src={data?.twitter_avatar}
          />
        </div>
        <div style={{ width: 'calc(100% - 70px)' }}>
          <div className="key__name">{data?.twitter_name}</div>
          <div className="key__twitter-name">{data?.twitter_username}</div>
        </div>
      </div>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex flexDirection={'column'} h={'80px'}>
          <div className="key__price">
            ${formatCommaNumber(dataKey?.usd_price || 0, 6)}
          </div>
          {dataPrice24h?.length >= 2 && (
            <div
              className={`key__change ${
                priceCurrent < priceLast24h ? 'red' : 'green'
              }`}
            >
              {priceCurrent < priceLast24h ? <DownIcon /> : <UpIcon />}
              {getPercentChange(priceLast24h, priceCurrent)}
            </div>
          )}
        </Flex>
        <div style={{ height: '80px', width: '100%' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={dataChart}
              margin={{
                right: 10,
                top: 15,
                left: 50,
                bottom: 15,
              }}
            >
              <Line
                dot={false}
                type="monotone"
                dataKey="price"
                stroke={priceLast7d > priceCurrent ? '#f6465d' : '#28c76f'}
                activeDot={{ r: 8 }}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Flex>

      <Flex mt={4} justifyContent={'space-between'}>
        <Box>
          <Box className="key__label">Total holders</Box>
          <Box className="key__value">{data?.holders || '--'}</Box>
        </Box>
        <Box>
          <Box className="key__label">Total supply</Box>
          <Box className="key__value">
            {formatCommaNumber(dataKey?.total_supply || 0, 2)}
          </Box>
        </Box>
      </Flex>
    </AppCard>
  );
};

export default AppKey;
