import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IStatistics } from 'src/pages/Dashboard';
import { StatItem } from 'src/pages/Dashboard/parts/PartTopCount';
import _ from 'lodash';

interface IStatItem {
  data?: IStatistics;
  prefix?: string;
  isNoted?: boolean;
  isPercent?: boolean;
  label: string;
}

const PartTopCount = ({ dataAllKey }: any) => {
  const [totalStatistics, setTotalStatistics] = useState<any>({});

  const getStatistics = async () => {
    if (dataAllKey && dataAllKey.length > 0) {
      const sumObject = {
        total_key: _.sumBy(dataAllKey, (obj: any) => +obj.balance) || 0,
        total_invest:
          _.sumBy(
            dataAllKey,
            (obj: any) => +obj.balance * +obj.usd_buy_price,
          ) || 0,
        total_current_value:
          _.sumBy(dataAllKey, (obj: any) => +obj.balance * +obj.usd_price) || 0,
      };

      setTotalStatistics(sumObject);
    } else {
      setTotalStatistics({});
    }
  };

  useEffect(() => {
    getStatistics().then();
  }, [dataAllKey]);

  const listCard = [
    {
      label: 'Total Key',
      data: {
        current: totalStatistics?.total_key || 0,
      },
      isPercent: true,
      prefix: ' ',
    },
    {
      label: 'Total Invest',
      data: {
        current: totalStatistics?.total_invest || 0,
      },
      prefix: '$',
    },
    {
      label: 'Current Value',
      data: {
        current: totalStatistics?.total_current_value || 0,
      },
      prefix: '$',
    },
    {
      label: 'Net Profit',
      data: {
        current:
          totalStatistics?.total_current_value -
            totalStatistics?.total_invest || 0,
      },
      prefix: '$',
    },
  ];

  return (
    <Flex flexDir={'column'} justify={'center'} alignItems={'center'}>
      <Box fontSize={28} fontWeight={700} py={4}>
        Portfolio
      </Box>

      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 4 }}
        mb={5}
        gridGap={{ base: 5 }}
        w={'full'}
      >
        {listCard.map((item: IStatItem, index: number) => {
          return (
            <StatItem
              key={index}
              data={item.data}
              label={item.label}
              prefix={item?.prefix || ''}
              isNoted={item?.isNoted}
              isPercent={item?.isPercent}
            />
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};

export default PartTopCount;
