import { Box, Flex, Show } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MENUS = [
  {
    name: 'Overview',
    path: '/',
  },
  {
    name: 'All Keys',
    path: '/all-keys',
  },
];

const Header = () => {
  const history = useHistory();

  return (
    <Box className="header">
      <Flex className="content-header">
        <Flex>
          <Flex
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => history.push('/')}
          >
            <img
              src="/images/logo-alpha.jpg"
              alt="logo"
              width={'45px'}
              className="header-logo"
            />
          </Flex>

          <Flex alignItems={'center'} fontSize={'18px'} fontWeight={700}>
            {MENUS.map((item, index) => {
              return (
                <Box
                  color={
                    history.location.pathname === item.path
                      ? '#ffffff'
                      : '#8d91a5'
                  }
                  cursor={'pointer'}
                  key={index}
                  ml={10}
                  title={item.name}
                  onClick={() => history.push(item.path)}
                >
                  {item.name}
                </Box>
              );
            })}
          </Flex>
        </Flex>
        <Show above="sm">
          <a href={'https://blocklens.io/'} target={'_blank'}>
            <img
              src="/images/logo.png"
              alt="logo"
              width={'155px'}
              className="header-logo"
            />
          </a>
        </Show>
      </Flex>
    </Box>
  );
};
export default Header;
