import {
  Show,
  SimpleGrid,
  Table,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Tbody,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Avatar,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AppCard, AppPagination } from 'src/components';
import rf from 'src/requests/RequestFactory';
import { formatCommaNumber, formatToPercent } from 'src/utils/utils-helper';
import { MinusIcon, PlusIcon } from 'src/assets/icons';

interface IData {
  balance: string;
  usd_buy_price: string;
  usd_price: string;
  total_volume: string;
  user: {
    address: string;
    id: number;
    twitter_name: string;
    twitter_avatar: string;
    twitter_username: string;
  };
}

const PartTable = () => {
  const { address }: { address: string } = useParams();
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<IData[]>([]);
  const [totalDocs, setTotalDocs] = useState<number>(0);
  const history = useHistory();

  const getDataKey = async () => {
    try {
      const res = await rf.getRequest('NewBitcoinRequest').getKeyTableDetail({
        address: address,
        page: page,
        limit: 10,
      });

      if (res?.result) {
        setData(res?.result);
        setTotalDocs(res.count);
      } else {
        setData([]);
      }
    } catch (e) {
      setData([]);
    }
  };

  useEffect(() => {
    getDataKey().then();
  }, [page, address]);

  const onChangePagination = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalDocs / 10);
  }, [totalDocs]);

  const _renderContentDesktop = () => {
    return (
      <Table className="box-table table-address">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Account</Th>
            <Th>Amount</Th>
            <Th>Avg Price Bought</Th>
            <Th>Total invested</Th>
            <Th>Current Price</Th>
            <Th>Current value</Th>
            <Th>PNL</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!!data.length ? (
            data?.map((item, index: number) => {
              const totalInvested = +item.balance * +item.usd_buy_price;
              const currentValue = +item.balance * +item.usd_price;

              return (
                <Tr
                  key={index}
                  cursor={'pointer'}
                  onClick={() => history.push(`/key/${item?.user.address}`)}
                >
                  <Td>{(page - 1) * 10 + index + 1}</Td>
                  <Td>
                    <Flex alignItems={'center'}>
                      <Avatar
                        name={item.user.twitter_username}
                        w={'40px'}
                        h={'40px'}
                        src={item.user.twitter_avatar}
                      />
                      <Flex
                        flexDir={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                      >
                        <Box ml={2}>{item.user.twitter_name}</Box>
                        <Box ml={2} color={'#8d91a5'}>
                          {item.user.twitter_username}
                        </Box>
                      </Flex>
                    </Flex>
                  </Td>

                  <Td>{formatCommaNumber(item.balance, 2)}</Td>
                  <Td>${formatCommaNumber(item.usd_buy_price, 2)}</Td>
                  <Td>
                    {totalInvested
                      ? `$${formatCommaNumber(totalInvested, 2)}`
                      : '--'}
                  </Td>
                  <Td>${formatCommaNumber(item.usd_price, 2)}</Td>
                  <Td>
                    {currentValue
                      ? `$${formatCommaNumber(currentValue, 2)}`
                      : '--'}
                  </Td>

                  {totalInvested && currentValue ? (
                    <Td
                      color={
                        totalInvested > currentValue ? '#ee5d50' : '#28c76f'
                      }
                    >
                      {totalInvested > currentValue ? '-' : '+'}
                      {formatToPercent(
                        Math.abs(currentValue / totalInvested - 1),
                      )}
                    </Td>
                  ) : (
                    <Td>--</Td>
                  )}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={7} border={0}>
                <Flex justifyContent={'center'}>No data...</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  };

  const _renderContentMobile = () => {
    if (!data.length) {
      return (
        <Flex padding="0 20px" justifyContent={'center'}>
          No data..
        </Flex>
      );
    }

    return (
      <Box padding="0 20px">
        <Accordion defaultIndex={0}>
          {data?.map((item, index: number) => {
            const totalInvested = +item.balance * +item.usd_buy_price;
            const currentValue = +item.balance * +item.usd_price;

            return (
              <AccordionItem key={index} className="box-accordion__item">
                {({ isExpanded }) => (
                  <Box
                    className={`box-accordion__item__bg ${
                      isExpanded ? 'active' : ''
                    }`}
                  >
                    <h2>
                      <AccordionButton>
                        <Box
                          className="text-title"
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          <span>{(page - 1) * 10 + index + 1}.</span>{' '}
                          {item.user.twitter_name}
                        </Box>
                        {isExpanded ? <MinusIcon /> : <PlusIcon />}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} className="box-accordion__panel">
                      <Flex justifyContent="space-between" mb={4}>
                        <span className="label">Amount</span>
                        <span>{formatCommaNumber(item.balance, 2)}</span>
                      </Flex>

                      <Flex justifyContent="space-between" mb={4}>
                        <span className="label">Avg Price Bought</span>
                        <Box as={'span'}>
                          ${formatCommaNumber(item.usd_buy_price, 2)}
                        </Box>
                      </Flex>

                      <Flex justifyContent="space-between" mb={4}>
                        <span className="label">Total invested</span>
                        <span>
                          {totalInvested
                            ? `$${formatCommaNumber(totalInvested, 2)}`
                            : '--'}
                        </span>
                      </Flex>

                      <Flex justifyContent="space-between" mb={4}>
                        <span className="label">Current price</span>
                        <span>${formatCommaNumber(item.usd_price, 2)}</span>
                      </Flex>

                      <Flex justifyContent="space-between" mb={4}>
                        <span className="label">Current value</span>
                        <span>
                          {currentValue
                            ? `$${formatCommaNumber(currentValue, 2)}`
                            : '--'}
                        </span>
                      </Flex>

                      <Flex justifyContent="space-between" mb={2}>
                        <span className="label">PNL</span>

                        {totalInvested && currentValue ? (
                          <Box
                            as={'span'}
                            color={
                              totalInvested > currentValue
                                ? '#ee5d50'
                                : '#28c76f'
                            }
                          >
                            {totalInvested > currentValue ? '-' : '+'}
                            {formatToPercent(
                              Math.abs(currentValue / totalInvested - 1),
                            )}
                          </Box>
                        ) : (
                          <Box as={'span'}>--</Box>
                        )}
                      </Flex>
                    </AccordionPanel>
                  </Box>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
    );
  };

  return (
    <SimpleGrid spacing={5}>
      <AppCard className="pd-0">
        <Show above="lg">{_renderContentDesktop()}</Show>
        <Show below="lg">
          <></>
        </Show>
        <Show below="lg">{_renderContentMobile()}</Show>

        <Flex justifyContent={'flex-end'} mt={'25px'} alignItems="flex-end">
          {totalPages > 1 && (
            <AppPagination
              pageCount={totalPages}
              forcePage={page - 1}
              onPageChange={onChangePagination}
            />
          )}
        </Flex>
      </AppCard>
    </SimpleGrid>
  );
};

export default PartTable;
