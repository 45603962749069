import { Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { GuestPage } from 'src/layouts';
import PartInfo from './parts/PartInfo';
import PartShowStatistics from './parts/PartShowStatistics';
import PartPriceChart from './parts/PartPriceChart';
import PartHoldersChart from './parts/PartHoldersChart';
import PartPieChart from './parts/PartPieChart';
import PartTable from './parts/PartTable';
import 'src/styles/pages/AllKeyDetail.scss';
import { useParams } from 'react-router-dom';
import rf from 'src/requests/RequestFactory';
import PartCard from './parts/PartCard';
import moment from 'moment';
import { orderBy } from 'lodash';

const KeyDetailPage = () => {
  const { address }: { address: string } = useParams();
  const [dataProfileInfo, setDataProfileInfo] = useState<any>({});
  const [dataKeyInfo, setDataKeyInfo] = useState<any>({});
  const [dataAllKey, setDataAllKey] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataHistoriesKey, setDataHistoriesKey] = useState<any>([]);

  const getProfileInfo = async () => {
    try {
      const res = await rf.getRequest('NewBitcoinRequest').getProfileInfo({
        player_address: address,
      });

      if (res.result) {
        setDataProfileInfo(res?.result);
      } else {
        setDataProfileInfo({});
      }
    } catch (e) {
      setDataProfileInfo({});
    }
  };

  const getKeysInfo = async () => {
    if (!dataProfileInfo?.twitter_username) {
      return;
    }

    try {
      const res = await rf.getRequest('NewBitcoinRequest').getKeysInfo({
        search: dataProfileInfo?.twitter_username || '',
      });

      if (res.result && !!res.result.length) {
        setDataKeyInfo(res?.result[0]);
      } else {
        setDataKeyInfo({});
      }
    } catch (e) {
      setDataKeyInfo({});
    }
  };

  const getDataAllKey = async () => {
    try {
      setIsLoading(true);
      const res = await rf.getRequest('NewBitcoinRequest').getKeyTableDetail({
        address: address,
        page: 1,
        limit: 2000,
      });

      if (res?.result) {
        setDataAllKey(res?.result);
      } else {
        setDataAllKey([]);
      }

      setIsLoading(false);
    } catch (e) {
      setDataAllKey([]);
      setIsLoading(false);
    }
  };

  const getKeyHistory = async () => {
    try {
      const res = await rf
        .getRequest('AnalyticRequest')
        .getHistoriesKey(address, { day: 10 });

      if (res && !!res.length) {
        let dataFormat = res.map((item: any) => {
          return {
            time: moment(item?.createdAt).valueOf(),
            portfolio_value: item?.usd_portfolio,
            price: item?.price,
            total_volume: item?.total_volume,
          };
        });
        dataFormat = orderBy(dataFormat, ['time'], ['asc']);

        setDataHistoriesKey(dataFormat);
      } else {
        setDataHistoriesKey([]);
      }
    } catch (e) {
      setDataHistoriesKey([]);
    }
  };

  useEffect(() => {
    getProfileInfo().then();
    getDataAllKey().then();
    getKeyHistory().then();
  }, [address]);

  useEffect(() => {
    getKeysInfo().then();
  }, [dataProfileInfo]);

  return (
    <GuestPage>
      <Flex mb={5} flexDir={'column'}>
        <PartInfo dataKey={dataProfileInfo} />
        <Flex flexDir={{ base: 'column', lg: 'row' }} gap={5} mb={5}>
          <PartShowStatistics
            holders={dataProfileInfo?.holders || 0}
            dataKeyInfo={dataKeyInfo}
          />
          <PartPriceChart dataChart={dataHistoriesKey} />
        </Flex>
        <PartCard dataAllKey={dataAllKey} />
        <Flex flexDir={{ base: 'column', lg: 'row' }} gap={5} mb={5}>
          <PartHoldersChart dataChart={dataHistoriesKey} />
          <PartPieChart dataAllKey={dataAllKey} isLoading={isLoading} />
        </Flex>
        <PartTable />
      </Flex>
    </GuestPage>
  );
};

export default KeyDetailPage;
