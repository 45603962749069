import rf from 'src/requests/RequestFactory';
import { useEffect, useState } from 'react';
import React from 'react';
import { Flex } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { formatCommaNumber } from 'src/utils/utils-helper';

const PartHeatChart = () => {
  const [data, setData] = useState<any[]>([]);
  const [dataColor, setDataColor] = useState<any[]>([]);

  const getDailyTradeVolume = async () => {
    try {
      const res = await rf.getRequest('AnalyticRequest').getKeysHeatMap();
      if (res && !!res.length) {
        const dataFormat = res.map((item: any) => {
          const getPrefix = () => {
            if (!+item.lastPrice24h || +item.lastPrice24h === +item.price)
              return '';
            if (+item.lastPrice24h < +item.price) return '+';
            return '-';
          };

          let changePercent = '';
          if (!!+item?.lastPrice24h && +item?.lastPrice24h !== +item?.price) {
            const percent =
              (Math.abs(+item.price - +item.lastPrice24h) /
                +item.lastPrice24h) *
              100;
            changePercent = `${getPrefix()}${percent.toFixed(2)}%`;
          } else {
            changePercent = `0%`;
          }

          return {
            x: item?.user_twitter_name,
            y: +item?.marketCap,
            z: changePercent,
            price: +item?.price,
          };
        });
        setData(dataFormat);

        const dataFormatColor = res.map((item: any) => {
          if (!+item.lastPrice24h) return '#8f9bba';
          if (+item.lastPrice24h === +item.price) return '#8f9bba';
          if (+item.lastPrice24h < +item.price) return '#0ecb81';
          return '#f6465d';
        });
        setDataColor(dataFormatColor);
      } else {
        setData([]);
      }
    } catch (e) {
      setData([]);
    }
  };

  useEffect(() => {
    getDailyTradeVolume().then();
  }, []);

  if (!data.length) {
    return (
      <Flex justifyContent={'center'} my={5}>
        No data...
      </Flex>
    );
  }

  const series = [
    {
      data: data,
    },
  ];

  const customTooltip: ApexTooltip['custom'] = (options: any) => {
    const data = options?.w?.config?.series[0]?.data[options?.dataPointIndex];
    return (
      '<div class="treemap-chart-tooltip">' +
      '<b>' +
      data.x +
      '</b>' +
      '<br/>' +
      '<b>Price:</b>' +
      ' ' +
      `$${formatCommaNumber(data?.price, 2)}` +
      '<br/>' +
      '<b>Change 24h:</b>' +
      ' ' +
      `${data.z}` +
      '</div>'
    );
  };

  const options: ApexOptions = {
    legend: {
      show: false,
    },
    chart: {
      height: 500,
      type: 'treemap',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      custom: customTooltip,
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      formatter: function (text, op?: any) {
        const a = op.w.config.series[0].data[op?.dataPointIndex];
        return [text, `$${formatCommaNumber(a?.price, 2)}`, a.z] as any;
      },
      offsetY: -4,
    },
    colors: dataColor,
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
  };

  return (
    <Chart series={series} options={options} type="treemap" height={400} />
  );
};

export default PartHeatChart;
