import { ReactNode, FC } from 'react';
import { Header, Footer } from 'src/layouts';
import { Box } from '@chakra-ui/react';
import React from 'react';
import 'src/styles/layout/Header.scss';

interface IBasePage {
  children?: ReactNode;
}

const GuestPage: FC<IBasePage> = ({ children }) => {
  return (
    <Box>
      <Header />
      <Box className={'main'} px={{ base: 4, '2xl': 0 }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default GuestPage;
