import { Box, Flex, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { AppCard } from 'src/components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import { formatCompactNumber, formatToPercent } from 'src/utils/utils-helper';
import { UpIcon, DownIcon, InfoIcon } from 'src/assets/icons';
import { IStatistics } from 'src/pages/Dashboard';
import CountUp from 'react-countup';

interface IStatItem {
  data?: IStatistics;
  prefix?: string;
  isNoted?: boolean;
  isPercent?: boolean;
  label: string;
  show24h?: boolean;
}

export const StatItem: FC<IStatItem> = ({
  data,
  label,
  prefix,
  isNoted,
  isPercent,
  show24h,
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState<boolean>(false);

  const _renderNumber = () => {
    if (!data?.current) {
      return '--';
    }

    if (prefix) {
      return `${prefix}${formatCompactNumber(+data?.current)}`;
    }

    return <CountUp end={+data?.current} />;
  };

  return (
    <AppCard className="box-top-count">
      <Flex className="box-top-count__title">
        {label}
        {isNoted && (
          <Tooltip
            backgroundColor={'#292E4A'}
            color={`#FFFFFF`}
            borderRadius={'6px'}
            fontSize={'14px'}
            fontWeight={400}
            isOpen={isLabelOpen}
            label={<Box>Provide coinmarketcap</Box>}
            placement="top"
            hasArrow
            py={2}
            px={4}
          >
            <Box
              ml={2}
              cursor="pointer"
              onMouseEnter={() => setIsLabelOpen(true)}
              onMouseLeave={() => setIsLabelOpen(false)}
              onClick={() => setIsLabelOpen(true)}
            >
              <InfoIcon />
            </Box>
          </Tooltip>
        )}
      </Flex>

      {show24h && <Box className="box-top-count__sub-title">(24h change)</Box>}

      <Flex className="box-top-count__desc" alignItems="center" mt={3}>
        <Box className="price" mr={4}>
          {_renderNumber()}
        </Box>
        {data?.current && data?.last24h ? (
          <Box
            className={`box-count ${
              data?.current >= data?.last24h ? 'green' : 'red'
            }`}
          >
            {data?.current >= data?.last24h ? <UpIcon /> : <DownIcon />}
            {isPercent
              ? formatToPercent(data?.current / data?.last24h - 1)
              : formatCompactNumber(Math.abs(data?.current - data?.last24h))}
          </Box>
        ) : (
          <></>
        )}
      </Flex>
    </AppCard>
  );
};

const PartTopCount = () => {
  const [totalStatistics, setTotalStatistics] = useState<any>({});

  const getStatisticsChange24h = async () => {
    try {
      const res = await rf
        .getRequest('AnalyticRequest')
        .getStatisticsChange24h();
      setTotalStatistics(res.total);
    } catch (e) {}
  };

  useEffect(() => {
    getStatisticsChange24h().then();
  }, []);

  const listStat: any = useMemo(() => {
    return [
      {
        label: 'Total Market Cap',
        data: {
          current: totalStatistics?.curr?.totalMarketCap || 0,
          last24h: totalStatistics?.last24h?.totalMarketCap || 0,
        },
        isPercent: true,
        prefix: '$',
      },
      {
        label: 'Total Users',
        data: {
          current: totalStatistics?.curr?.totalUsers || 0,
          last24h: totalStatistics?.last24h?.totalUsers || 0,
        },
        isPercent: true,
      },
      {
        label: 'Total Trade Volume',
        data: {
          current: totalStatistics?.curr?.totalTradeVolume || 0,
          last24h: totalStatistics?.last24h?.totalTradeVolume || 0,
        },
        isPercent: true,
        prefix: '$',
      },
      {
        label: 'Total Trades',
        data: {
          current: totalStatistics?.curr?.totalTrades || 0,
          last24h: totalStatistics?.last24h?.totalTrades || 0,
        },
        isPercent: true,
      },
    ];
  }, [totalStatistics]);

  return (
    <SimpleGrid
      columns={{ sm: 1, md: 2, lg: 4 }}
      mb={5}
      gridGap={{ base: 5 }}
    >
      {listStat.map((item: IStatItem, index: number) => {
        return (
          <StatItem
            key={index}
            data={item.data}
            label={item.label}
            show24h
            prefix={item?.prefix || ''}
            isNoted={item?.isNoted}
            isPercent={item?.isPercent}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default PartTopCount;
