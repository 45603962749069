import BigNumber from 'bignumber.js';
import moment from 'moment';
const commaNumber = require('comma-number');

export const SI = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 't' },
  { value: 1e15, symbol: 'q' },
  { value: 1e18, symbol: 'Q' },
  { value: 1e21, symbol: 's' },
  { value: 1e24, symbol: 'S' },
  { value: 1e27, symbol: 'o' },
  { value: 1e30, symbol: 'n' },
  { value: 1e33, symbol: 'd' },
  { value: 1e36, symbol: 'U' },
  { value: 1e39, symbol: 'D' },
  { value: 1e42, symbol: 'T' },
  { value: 1e45, symbol: 'Qt' },
  { value: 1e48, symbol: 'Qd' },
  { value: 1e51, symbol: 'Sd' },
  { value: 1e54, symbol: 'St' },
  { value: 1e57, symbol: 'O' },
  { value: 1e60, symbol: 'N' },
  { value: 1e63, symbol: 'v' },
  { value: 1e66, symbol: 'c' },
];

export const isString = (value: unknown) => {
  return typeof value === 'string';
};

export const getErrorMessage = (err: any) => {
  const REGEX_GET_MESSAGE = /execution reverted:([^"]*)/gm;
  if (err.message?.includes('execution reverted:')) {
    const match = REGEX_GET_MESSAGE.exec(err.message);
    return match ? match[1] : 'Something went wrong!';
  }
  if (isString(err)) {
    return err;
  }
  if (err.message && isString(err.message)) {
    return err.message;
  }
  return 'Something went wrong!';
};

export const formatShortText = (
  startIndex: number,
  endIndex: number,
  text: string,
) => {
  const startText = text?.substring(0, startIndex);
  const endText = text?.substring(text.length - endIndex);
  return startText?.concat('...')?.concat(endText);
};

export const formatTimestamp = (
  timestamp: number,
  format = 'DD/MM/YYYY HH:mm',
): string => {
  if (!timestamp) {
    return 'TBA';
  }

  return moment(timestamp).format(format);
};

export const filterParams = (params: any) => {
  return Object.fromEntries(Object.entries(params).filter(([_, v]) => v));
};

export function formatCompactNumber(number: number | BigNumber | string) {
  if (number < 1000) {
    return BigNumber(BigNumber(number).toFixed(2)).toFormat();
  } else if (number >= 1000 && number < 1_000_000) {
    return (+number / 1000).toFixed(2) + 'K';
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (+number / 1_000_000).toFixed(2) + 'M';
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (+number / 1_000_000_000).toFixed(1) + 'B';
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (+number / 1_000_000_000_000).toFixed(2) + 'T';
  }
}

export function formatToPercent(
  number: string | number | BigNumber,
  decimalPlaces = 2,
): string {
  const newValue = new BigNumber(number)
    .multipliedBy(100)
    .toFixed(decimalPlaces);

  return new BigNumber(newValue).toString() + '%';
}

export const roundNumber = (
  number: number | string | BigNumber,
  roundMode = BigNumber.ROUND_DOWN,
  decimals = 18,
) => {
  const newNumber = new BigNumber(number).toFixed(decimals, roundMode);
  return new BigNumber(newNumber).toString();
};

const _formatLargeNumberIfNeed = (number: string, digits = 0) => {
  if (new BigNumber(number).comparedTo(1000) < 0) {
    return commaNumber(
      new BigNumber(Number(number).toFixed(digits)).toString(),
      ',',
      '.',
    );
  }
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const num = parseFloat(number);
  let i;
  for (i = SI.length - 1; i > 0; i--) {
    if (num >= SI[i].value) {
      break;
    }
  }
  return (
    BigNumber(num / SI[i].value)
      .toFixed(digits, BigNumber.ROUND_DOWN)
      .toString()
      .replace(rx, '$1') + SI[i].symbol
  );
};

export function formatNumber(
  value: string | number | BigNumber,
  decimalPlaces = 4,
): string {
  if (!value || new BigNumber(value || 0).isZero()) {
    return '--';
  }

  if (
    new BigNumber(value).isGreaterThan(0) &&
    new BigNumber(value).isLessThan(0.00000001)
  ) {
    return '<0.00000001';
  }

  return _formatLargeNumberIfNeed(
    roundNumber(value, BigNumber.ROUND_DOWN, decimalPlaces),
    decimalPlaces,
  );
}

export const formatCommaNumber = (
  number: number | string | BigNumber,
  decimals: number,
) => {
  if (new BigNumber(number || 0).isZero()) {
    return '--';
  }
  return commaNumber(
    new BigNumber(Number(number).toFixed(decimals)).toString(),
    ',',
    '.',
  );
};
