import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Show,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { AppCard } from 'src/components';
import React, { FC, useEffect, useState } from 'react';
import { MinusIcon, PlusIcon } from 'src/assets/icons';
import rf from 'src/requests/RequestFactory';
import { IStatistics } from '../index';
import { formatCommaNumber, formatToPercent } from 'src/utils/utils-helper';
import { useHistory } from 'react-router';

interface IKey {
  marketCap: number;
  player: string;
  price: number;
  trade_amount: number;
  user_twitter_username: string;
  user_twitter_name: string;
  max_price: number;
  min_price: number;
}

interface IPartTopAddress {
  totalSupply?: IStatistics;
}

const PartTopKey: FC<IPartTopAddress> = ({ totalSupply }) => {
  const [keys, setKeys] = useState<IKey[]>([]);
  const history = useHistory();

  const getTopKeys = async () => {
    try {
      const res = await rf.getRequest('AnalyticRequest').getTopKeys();
      setKeys(res);
    } catch (e) {
      setKeys([]);
    }
  };

  useEffect(() => {
    getTopKeys().then();
  }, []);

  const getPercentChange = (min: number, max: number) => {
    if (!max) return '0%';
    if (!min) return '+100%';
    const change = Math.abs(max - min);
    return `+${formatToPercent(change / min)}`;
  };

  const _renderContentDesktop = () => {
    return (
      <Table className="box-table table-address">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Key</Th>
            <Th>Price</Th>
            <Th>24h</Th>
            <Th textAlign={'right'}>24h volume</Th>
            <Th textAlign={'right'}>Marketcap</Th>
          </Tr>
        </Thead>
        <Tbody>
          {keys.length ? (
            keys?.map((item: IKey, index: number) => (
              <Tr
                key={index}
                cursor={'pointer'}
                onClick={() => history.push(`key/${item?.player}`)}
              >
                <Td>{index + 1}</Td>
                <Td>{item.user_twitter_name || item.player}</Td>
                <Td>
                  {item.price ? `$${formatCommaNumber(item.price, 2)}` : '--'}
                </Td>
                <Td color={'#0ecb81'}>
                  {getPercentChange(item.min_price, item.max_price)}
                </Td>
                <Td textAlign={'right'}>
                  {item.trade_amount
                    ? `$${formatCommaNumber(item.trade_amount, 2)}`
                    : '--'}
                </Td>
                <Td textAlign={'right'}>
                  {item.marketCap
                    ? `$${formatCommaNumber(item.marketCap, 2)}`
                    : '--'}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7} border={0}>
                <Flex justifyContent={'center'}>No data...</Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  };

  const _renderContentMobile = () => {
    if (!keys.length) {
      return (
        <Flex padding="0 20px" justifyContent={'center'}>
          No data..
        </Flex>
      );
    }

    return (
      <Box padding="0 20px">
        <Accordion defaultIndex={0}>
          {keys?.map((item: IKey, index: number) => (
            <AccordionItem key={index} className="box-accordion__item">
              {({ isExpanded }) => (
                <Box
                  className={`box-accordion__item__bg ${
                    isExpanded ? 'active' : ''
                  }`}
                >
                  <h2>
                    <AccordionButton>
                      <Box
                        className="text-title"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        <span>{index + 1}.</span>{' '}
                        {item.user_twitter_name || item.player}
                      </Box>
                      {isExpanded ? <MinusIcon /> : <PlusIcon />}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} className="box-accordion__panel">
                    <Flex justifyContent="space-between" mb={2}>
                      <span className="label">Price</span>
                      <span>
                        {item.price
                          ? `$${formatCommaNumber(item.price, 2)}`
                          : '--'}
                      </span>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                      <span className="label">24h</span>
                      <Box as={'span'} color={'#0ecb81'}>
                        {getPercentChange(item.min_price, item.max_price)}
                      </Box>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                      <span className="label">24h volume</span>
                      <span>
                        {item.trade_amount
                          ? `$${formatCommaNumber(item.trade_amount, 2)}`
                          : '--'}
                      </span>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                      <span className="label">Marketcap</span>
                      <span>
                        {item.marketCap
                          ? `$${formatCommaNumber(item.marketCap, 2)}`
                          : '--'}
                      </span>
                    </Flex>
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    );
  };

  return (
    <SimpleGrid spacing={5}>
      <AppCard className="pd-0">
        <Box className="box-top-count__title px-20" mb={4}>
          Top 10 Key Gainner 24h
        </Box>
        <Show above="lg">{_renderContentDesktop()}</Show>
        <Show below="lg">{_renderContentMobile()}</Show>
      </AppCard>
    </SimpleGrid>
  );
};

export default PartTopKey;
