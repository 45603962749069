import { Box, Flex } from '@chakra-ui/react';
import {
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AppCard } from 'src/components';
import { formatCommaNumber, formatTimestamp } from 'src/utils/utils-helper';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: any;
  }>;
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="dashboard_sui_gar__chart-tooltip-text">
        {formatTimestamp(payload[0]?.payload?.time, 'MMMM DD YYYY')} <br />
        {payload.map((item: any, index) => {
          return (
            <div key={index}>
              {item?.name === 'price' ? 'Price' : 'Volume'}:{' '}
              <b>${formatCommaNumber(+item?.value, 5)}</b>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

const PartPriceChart = ({ dataChart }: any) => {
  return (
    <AppCard className="box-about">
      <Box className="box-about__title">Token Price & Volume</Box>
      {!dataChart.length ? (
        <Flex justifyContent={'center'} alignItems={'center'} h={'full'}>
          No data...
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={dataChart}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis
              dataKey="time"
              tickLine={false}
              tickFormatter={(time: number) => formatTimestamp(time, 'MMM DD')}
              padding={{
                right: 10,
                left: 10,
              }}
            />
            <YAxis
              padding={{ top: 15 }}
              label={{
                value: 'Price($)',
                position: 'top',
                style: {
                  fontSize: '16px',
                  fontWeight: 700,
                },
              }}
              yAxisId="left"
              orientation="left"
              dataKey={'price'}
              tickFormatter={(value: number) =>
                value === 0 ? '0' : `${value}`
              }
            />
            <YAxis
              padding={{ top: 15 }}
              label={{
                value: 'Volume($)',
                position: 'top',
                style: {
                  fontSize: '16px',
                  fontWeight: 700,
                },
              }}
              yAxisId="right"
              dataKey={'total_volume'}
              orientation="right"
              tickFormatter={(value: number) =>
                value === 0 ? '0' : `${value}`
              }
            />
            <Tooltip cursor={{ fill: '#414558' }} content={<CustomTooltip />} />
            <Bar
              dataKey="total_volume"
              yAxisId="right"
              barSize={20}
              fill="#8884d8"
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="price"
              stroke="#82ca9d"
              strokeWidth={3}
              yAxisId="left"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </AppCard>
  );
};

export default PartPriceChart;
