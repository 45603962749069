import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class AnalyticRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getTopHolders() {
    const url = `/analytic/top-20-sui-holders-analytic`;
    return this.get(url);
  }

  getActiveAddress(payload: { timeInterval: string }) {
    const url = `/analytic/active-address-by-timeseries`;
    return this.get(url, payload);
  }

  getContractsDeployed(payload: { timeInterval: string }) {
    const url = `/analytic/contracts-deployed-by-timeseries`;
    return this.get(url, payload);
  }

  getTransactions(payload: { timeInterval: string }) {
    const url = `/analytic/tx-by-timeseries`;
    return this.get(url, payload);
  }

  getActiveAddress24h() {
    const url = `/analytic/24h-analysis-open-official-mainnet/active-address`;
    return this.get(url);
  }

  getPackagesDeployed24h() {
    const url = `/analytic/24h-analysis-open-official-mainnet/contracts-deployed`;
    return this.get(url);
  }

  getTransactions24h() {
    const url = `/analytic/24h-analysis-open-official-mainnet/transaction`;
    return this.get(url);
  }

  getGarMetrics(payload: { from?: number; to?: number }) {
    const url = `/analytic/gas-metrics`;
    return this.get(url, payload);
  }

  getDailyActive(payload: { from?: number; to?: number; resolution?: string }) {
    const url = `/analytic/address-metrics`;
    return this.get(url, payload);
  }

  getDailyTradeVolume() {
    const url = `/trades/daily-volume`;
    return this.get(url);
  }

  getStatisticsChange24h() {
    const url = `/statistics/change-24h`;
    return this.get(url);
  }

  getKeysHeatMap() {
    const url = `/keys/heatmap`;
    return this.get(url);
  }

  getTopKeys() {
    const url = `/keys/gainer`;
    return this.get(url);
  }

  getKeys(payload: any) {
    const url = `/keys`;
    return this.get(url, payload);
  }

  getKeysLastActive() {
    const url = `/keys/last-active`;
    return this.get(url);
  }

  getHistoriesKey(address: string, payload: any) {
    const url = `/keys/histories/${address}`;
    return this.get(url, payload);
  }

  getDailyUsers() {
    const url = `/statistics/daily-users`;
    return this.get(url);
  }
}
