import React, { useEffect, useState } from 'react';
import {
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import rf from 'src/requests/RequestFactory';
import {
  formatCommaNumber,
  formatNumber,
  formatTimestamp,
} from 'src/utils/utils-helper';
import { isMobile } from 'react-device-detect';
import { orderBy } from 'lodash';
import moment from 'moment';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: any;
  }>;
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="dashboard_sui_gar__chart-tooltip-text">
        {formatTimestamp(payload[0]?.payload?.time, 'MMMM DD YYYY')} <br />
        {payload.map((item: any, index) => {
          return (
            <div key={index}>
              {item?.name === 'dailyUsers' ? 'Total Users' : 'Daily New User'}:{' '}
              <b>{formatCommaNumber(Math.abs(item?.value), 0)}</b>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

const PartUserChart = () => {
  const [data, setData] = useState<any[]>([]);

  const getDailyUsers = async () => {
    try {
      const res = await rf.getRequest('AnalyticRequest').getDailyUsers();
      let dataFormat = res.map((item: any) => {
        return {
          ...item,
          time: moment(item.time).valueOf(),
        };
      });

      dataFormat = orderBy(dataFormat, ['time'], ['asc']);

      const dataUser = dataFormat.map((item: any, index: number) => {
        if (+index > 0) {
          const newUser =
            dataFormat[index]?.dailyUsers - dataFormat[index - 1]?.dailyUsers;
          return {
            ...item,
            newUser: newUser,
          };
        }
        return {
          ...item,
          newUser: 0,
        };
      });
      setData(dataUser);
    } catch (e) {
      setData([]);
    }
  };

  useEffect(() => {
    getDailyUsers().then();
  }, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          bottom: 5,
          left: 15,
          right: 15,
        }}
      >
        <XAxis
          dataKey="time"
          tickLine={false}
          tickFormatter={(time: number) => formatTimestamp(time, 'MMM DD')}
          padding={{
            right: 10,
            left: 10,
          }}
        />
        <YAxis
          padding={{ top: 15 }}
          label={{
            value: 'Total Users',
            position: 'top',
            style: {
              fontSize: '16px',
              fontWeight: 700,
            },
          }}
          yAxisId="right"
          orientation="right"
          dataKey={'dailyUsers'}
          tickFormatter={(value: number) =>
            value === 0 ? '0' : formatNumber(Math.abs(value), 2)
          }
        />
        <YAxis
          padding={{ top: 15 }}
          label={{
            value: 'New Users',
            position: 'top',
            style: {
              fontSize: '16px',
              fontWeight: 700,
            },
          }}
          yAxisId="left"
          orientation="left"
          dataKey={'newUser'}
          tickFormatter={(value: number) =>
            value === 0 ? '0' : formatNumber(Math.abs(value), 2)
          }
        />

        <Tooltip cursor={{ fill: '#414558' }} content={<CustomTooltip />} />
        <Bar dataKey="newUser" yAxisId="left" barSize={20} fill="#8884d8" />
        <Line
          dot={false}
          type="monotone"
          dataKey="dailyUsers"
          stroke="#82ca9d"
          strokeWidth={3}
          yAxisId="right"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PartUserChart;
