import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

const Footer = () => {
  return (
    <Flex className="footer">
      <a href={'https://blocklens.io/'} target={'_blank'}>
        <img
          src="/images/logo.png"
          alt="logo"
          width={'155px'}
          className="header-logo"
        />
      </a>

      <Box>Powerd by BlockLens</Box>
    </Flex>
  );
};
export default Footer;
