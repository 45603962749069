import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class NewBitcoinRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.newBitcoinUrlApi;
  }

  getKeysInfo(payload: any) {
    const url = `/nbc-keys/tokens`;
    return this.get(url, payload);
  }

  getProfileInfo(payload: any) {
    const url = `/nbc-keys/profile/v3`;
    return this.get(url, payload);
  }

  getChartDataKey(payload: any) {
    const url = `/nbc-keys/chart/data`;
    return this.get(url, payload);
  }

  getKeyTableDetail(payload: any) {
    const url = `/player-share/holding`;
    return this.get(url, payload);
  }
}
