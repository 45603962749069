import React, { useEffect, useState } from 'react';
import { AppCard } from 'src/components';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Box, Flex } from '@chakra-ui/react';
import { orderBy, sumBy } from 'lodash';

const PartPieChart = ({ dataAllKey, isLoading }: any) => {
  const [data, setData] = useState<any[]>([]);

  const getDataChart = async () => {
    if (dataAllKey && !!dataAllKey.length) {
      let dataChart = dataAllKey.map((item: any) => {
        return {
          balance: +item.balance,
          name: item.user.twitter_name,
        };
      });

      dataChart = dataChart.filter((item: any) => +item.balance > 0);

      if (dataChart.length > 11) {
        dataChart = orderBy(dataChart, 'balance', 'desc');

        const topKey = dataChart.slice(0, 9);
        const otherKey = dataChart.slice(9, dataChart.length);

        const totalBalanceOfOtherKey = sumBy(otherKey, 'balance');

        dataChart = [
          ...topKey,
          {
            name: 'Others',
            balance: totalBalanceOfOtherKey,
          },
        ];
        setData(dataChart);
        return;
      }

      setData(dataChart);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getDataChart().then();
  }, [dataAllKey]);

  const series = data.map((item) => item.balance);
  const options: ApexOptions = {
    chart: {
      type: 'pie',
      height: 380,
      width: '100%',
    },
    labels: data.map((item) => item.name),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <AppCard className="box-about" w={{ base: '100%', lg: '50%' }} height={400}>
      <Box className="box-about__title">Portfolio Share</Box>

      {isLoading ? (
        <Flex h={'full'} justifyContent={'center'} alignItems={'center'}>
          Loading...
        </Flex>
      ) : (
        <>
          {!!data.length ? (
            <Chart options={options} series={series} type="pie" height={300} />
          ) : (
            <Flex h={'full'} justifyContent={'center'} alignItems={'center'}>
              No data...
            </Flex>
          )}
        </>
      )}
    </AppCard>
  );
};

export default PartPieChart;
