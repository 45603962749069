import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import { CloseIcon, SearchIcon } from 'src/assets/icons';
import { AppPagination } from 'src/components';
import AppSelect from 'src/components/AppSelect';
import PartItemKey from './PartItemKey';
import { GuestPage } from 'src/layouts';
import { isMobile } from 'react-device-detect';
import rf from '../../requests/RequestFactory';
import { debounce } from 'lodash';
import { filterParams } from 'src/utils/utils-helper';

const OPTIONS_SORT = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Token name A-Z',
    value: 'Token name A-Z',
  },
  {
    label: 'Token name Z-A',
    value: 'Token name Z-A',
  },
  {
    label: 'Price low to high',
    value: 'Price low to high',
  },
  {
    label: 'Price high to low',
    value: 'Price high to low',
  },
  {
    label: 'Supply low to high',
    value: 'Supply low to high',
  },
  {
    label: 'Supply high to low',
    value: 'Supply high to low',
  },
  // {
  //   label: 'Holders low to high',
  //   value: 'Holders low to high',
  // },
  // {
  //   label: 'Holders high to low',
  //   value: 'Holders high to low',
  // },
];

const DEBOUNCE_TIME = 1000;

const AllKeyPage = () => {
  const [valueSearch, setValueSearch] = useState<string>('');
  const [valueSort, setValueSort] = useState<string>('default');
  const [page, setPage] = useState<number>(1);
  const [sortCol, setSortCol] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onChangePagination = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const onSort = (type: string) => {
    setValueSort(type);

    switch (type) {
      case 'default': {
        setSortType('');
        setSortCol('');
        return;
      }

      case 'Token name A-Z': {
        setSortType('1');
        setSortCol('user_twitter_username');
        return;
      }

      case 'Token name Z-A': {
        setSortType('-1');
        setSortCol('user_twitter_username');
        return;
      }

      case 'Price low to high': {
        setSortType('1');
        setSortCol('usd_price');
        return;
      }

      case 'Price high to low': {
        setSortType('-1');
        setSortCol('usd_price');
        return;
      }

      case 'Supply low to high': {
        setSortType('1');
        setSortCol('total_supply_number');
        return;
      }

      case 'Supply high to low': {
        setSortType('-1');
        setSortCol('total_supply_number');
        return;
      }

      default: {
        setSortType('');
        setSortCol('');
        return;
      }
    }
  };

  const getKeys = async () => {
    setIsLoading(true);
    try {
      const res = await rf.getRequest('AnalyticRequest').getKeys(
        filterParams({
          page: page,
          limit: 20,
          search: valueSearch,
          sort_type: sortType,
          sort_by: sortCol,
        }),
      );
      setData(res?.docs);
      setIsLoading(false);
      setTotalPages(res?.totalPages);
    } catch (e) {
      setIsLoading(false);
      setData([]);
    }
  };

  const debounceFetchTableData = useCallback(debounce(getKeys, DEBOUNCE_TIME), [
    page,
    valueSearch,
    sortCol,
    sortType,
  ]);

  useEffect(() => {
    debounceFetchTableData();
    return () => {
      debounceFetchTableData.cancel();
    };
  }, [debounceFetchTableData]);

  return (
    <GuestPage>
      <Flex mb={5} flexDir={'column'}>
        <Flex
          pb={10}
          justifyContent={'flex-end'}
          gap={'10px'}
          direction={{ base: 'column', md: 'row' }}
        >
          <InputGroup w={{ base: 'full', md: '500px' }}>
            <Input
              focusBorderColor="none"
              errorBorderColor="#69758c"
              placeholder="Enter to Search..."
              size="md"
              value={valueSearch}
              onChange={(e) => setValueSearch(e.target.value.trim())}
              bg={'#101530'}
              color={'white'}
              fontSize={14}
              borderColor={'#69758c'}
            />
            <InputRightElement>
              {valueSearch ? (
                <Box
                  onClick={() => {
                    setValueSearch('');
                  }}
                  cursor={'pointer'}
                >
                  <CloseIcon className="icon_close" />
                </Box>
              ) : (
                <>
                  <SearchIcon />
                </>
              )}
            </InputRightElement>
          </InputGroup>
          <Box>
            <AppSelect
              width={isMobile ? 'full' : '200px'}
              options={OPTIONS_SORT}
              value={valueSort}
              onChange={(value) => {
                onSort(value);
              }}
              size="medium"
              showFullName
            />
          </Box>
        </Flex>

        {isLoading ? (
          <Flex justifyContent={'center'} mt={10}>
            Loading...
          </Flex>
        ) : (
          <>
            {!data.length ? (
              <Flex justifyContent={'center'} mt={10}>
                No data
              </Flex>
            ) : (
              <>
                <SimpleGrid
                  columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
                  mb={5}
                  gap={5}
                >
                  {data.map((dataKey, index) => {
                    return <PartItemKey data={dataKey} key={index} />;
                  })}
                </SimpleGrid>
                <Flex
                  justifyContent={'flex-end'}
                  mt={'25px'}
                  alignItems="flex-end"
                >
                  {totalPages > 1 && (
                    <AppPagination
                      pageCount={totalPages}
                      forcePage={page - 1}
                      onPageChange={onChangePagination}
                    />
                  )}
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
    </GuestPage>
  );
};

export default AllKeyPage;
