import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import rf from 'src/requests/RequestFactory';
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';
import {
  formatCommaNumber,
  formatNumber,
  formatTimestamp,
} from 'src/utils/utils-helper';
import { Box, Flex } from '@chakra-ui/react';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { isMobile } from 'react-device-detect';

interface ITradeVolume {
  action_type: string;
  createdAt: string;
  trade_time: string;
  updatedAt: string;
  volume: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: any;
  }>;
}

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div className="dashboard_sui_gar__chart-tooltip-text">
        {formatTimestamp(payload[0]?.payload?.time, 'MMMM DD YYYY')} <br />
        {payload.map((item: any, index) => {
          return (
            <div key={index}>
              {item?.name === 'volumeBuy' ? 'Volume Buy' : 'Volume Sell'}:{' '}
              <b>${formatCommaNumber(Math.abs(item?.value), 0)}</b>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

const PartDailyTradesVolume = () => {
  const [data, setData] = useState<any[]>([]);

  const getDailyTradeVolume = async () => {
    try {
      const res = await rf.getRequest('AnalyticRequest').getDailyTradeVolume();
      if (res?.buy?.length && res?.buy) {
        const dataFormat = res?.buy?.map((itemBuy: ITradeVolume) => {
          const dataSell = res?.sell.find(
            (itemSell: ITradeVolume) =>
              itemSell.trade_time === itemBuy.trade_time,
          );
          return {
            volumeBuy: itemBuy.volume,
            volumeSell: -dataSell.volume,
            time: moment(itemBuy?.trade_time).valueOf(),
          };
        });
        setData(dataFormat);
      } else {
        setData([]);
      }
    } catch (e) {
      setData([]);
    }
  };

  useEffect(() => {
    getDailyTradeVolume().then();
  }, []);

  const BarArray = [
    {
      dataKey: 'volumeBuy',
      color: '#0ecb81',
      name: 'Volume Buy',
    },
    {
      dataKey: 'volumeSell',
      color: '#f6465d',
      name: 'Volume Sell',
    },
  ];

  if (!data.length) {
    return (
      <Flex justifyContent={'center'} my={5}>
        No data...
      </Flex>
    );
  }

  const _renderLegend = (props: Props) => {
    const { payload } = props;
    if (!payload) return;

    return (
      <Flex justifyContent={'center'}>
        <Flex
          className="dashboard_sui_gar--legend"
          justifyContent={'center'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap={!isMobile ? 10 : 2}
          flexDirection={['column', 'row']}
        >
          {payload.map((entry: any, index) => {
            const { value, color } = entry;
            return (
              <Flex key={`item-${index}`} alignItems={'center'}>
                <Box
                  style={{
                    backgroundColor: color,
                  }}
                  w={5}
                  height={3}
                  mr={2}
                />
                {value}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={400}
        data={data}
        stackOffset="sign"
        margin={{
          top: 15,
          bottom: 5,
          left: isMobile ? -20 : 0,
        }}
      >
        <XAxis
          tickLine={false}
          axisLine={false}
          dataKey="time"
          tickFormatter={(time: number) => formatTimestamp(time, 'MMM DD')}
          padding={{
            right: 10,
            left: 10,
          }}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickFormatter={(value: number) =>
            value === 0 ? '0' : formatNumber(Math.abs(value), 0)
          }
        />
        <Tooltip cursor={{ fill: '#414558' }} content={<CustomTooltip />} />
        <Legend
          payload={BarArray.map((el) => ({
            ...el,
            value: el.name,
          }))}
          content={_renderLegend}
        />
        <ReferenceLine y={0} stroke="#6C7080" />
        <Bar dataKey="volumeBuy" fill="#0ecb81" stackId="stack" />
        <Bar dataKey="volumeSell" fill="#f6465d" stackId="stack" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PartDailyTradesVolume;
