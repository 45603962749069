import React from 'react';
import { GuestPage } from 'src/layouts';
import 'src/styles/pages/Dashboard.scss';
import PartChart from './parts/PartChart';
import PartTopCount from './parts/PartTopCount';
import PartTopKey from './parts/PartTopKey';
import PartKeys from './parts/PartKey';
import { AppCard } from 'src/components';
import PartHeatChart from './parts/PartHeatChart';
import { Box } from '@chakra-ui/react';

export interface IStatistics {
  current?: number;
  last24h?: number;
}

const Dashboard = () => {
  return (
    <GuestPage>
      <PartTopCount />
      <PartKeys />
      <PartChart />
      <AppCard className="box-about" mb={5}>
        <Box className="box-about__title">Heatmap Key Price 24h</Box>
        <PartHeatChart />
      </AppCard>
      <PartTopKey />
    </GuestPage>
  );
};
export default Dashboard;
