import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { AppCard } from 'src/components';
import { formatCommaNumber } from 'src/utils/utils-helper';

interface IPartShowStatistics {
  holders: number | string;
  dataKeyInfo: {
    usd_price: number | string;
    total_supply_number: number;
  };
}

const PartShowStatistics: FC<IPartShowStatistics> = ({
  holders,
  dataKeyInfo,
}) => {
  return (
    <AppCard w={{ base: '100%', lg: '40%' }}>
      <Flex flexDir={'column'} gap={5} justifyContent={'space-between'}>
        <Flex flexDir={'column'} className="key-detail">
          <Box className="key-detail__name">PRICE</Box>
          <Flex className="key-detail__info" justifyContent={'space-between'}>
            <Box className="key-detail__price">
              {dataKeyInfo?.usd_price
                ? `$${formatCommaNumber(dataKeyInfo?.usd_price || 0, 3)}`
                : '--'}
            </Box>
          </Flex>
        </Flex>
        <Flex flexDir={'column'} className="key-detail">
          <Box className="key-detail__name">SUPPLY</Box>
          <Flex className="key-detail__info" justifyContent={'space-between'}>
            <Box className="key-detail__price">
              {formatCommaNumber(dataKeyInfo?.total_supply_number || 0, 2)}
            </Box>
          </Flex>
        </Flex>
        <Flex flexDir={'column'} className="key-detail">
          <Box className="key-detail__name">MARKET CAP</Box>
          <Flex className="key-detail__info" justifyContent={'space-between'}>
            <Box className="key-detail__price">
              {dataKeyInfo?.usd_price && dataKeyInfo?.total_supply_number
                ? `$${formatCommaNumber(
                    dataKeyInfo?.total_supply_number * +dataKeyInfo.usd_price ||
                      0,
                    2,
                  )}`
                : '--'}
            </Box>
          </Flex>
        </Flex>
        <Flex flexDir={'column'} className="key-detail">
          <Box className="key-detail__name">HOLDERS</Box>
          <Flex className="key-detail__info" justifyContent={'space-between'}>
            <Box className="key-detail__price">{holders || '--'}</Box>
          </Flex>
        </Flex>
      </Flex>
    </AppCard>
  );
};

export default PartShowStatistics;
