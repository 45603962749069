import { Box, SimpleGrid } from '@chakra-ui/react';
import { AppCard } from 'src/components';
import React from 'react';
import PartDailyTradesVolume from './PartDailyTradesVolume';
import PartUserChart from './PartUserChart';

const PartChart = () => {
  return (
    <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={5} mb={5}>
      <AppCard className="box-about">
        <Box className="box-about__title">Daily Trades Volume</Box>
        <PartDailyTradesVolume />
      </AppCard>
      <AppCard className="box-about">
      <Box className="box-about__title">Daily New Users</Box>
        <PartUserChart />
      </AppCard>
    </SimpleGrid>
  );
};

export default PartChart;
