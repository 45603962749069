import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import Dashboard from 'src/pages/Dashboard';
import { clearUser } from './store/myAccount';
import Storage from 'src/utils/storage';
import AllKeyPage from './pages/AllKeyPage';
import KeyDetailPage from './pages/AllKeyDetailPage';

/**
 * Main App routes.
 */

const Routes: FC<RouteComponentProps> = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();
  const accessToken = Storage.getAccessToken();

  const isExpireTimeToken =
    Storage.getExpireTimeToken() &&
    new Date().getTime() >= Number(Storage.getExpireTimeToken());

  // scroll to top when route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!accessToken || isExpireTimeToken) {
      dispatch(clearUser());
      return;
    }
  }, [accessToken]);

  return (
    <>
      <Switch>
        <Route path={'/'} exact component={Dashboard} />
        <Route path={'/key/:address'} exact component={KeyDetailPage} />;
        <Route path={'/all-keys'} exact component={AllKeyPage} />;
      </Switch>
    </>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <Router>
        <RoutesHistory />
      </Router>
    </>
  );
};

/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
