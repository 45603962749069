import { Avatar, Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { AppCard } from 'src/components';

interface IPartInfo {
  dataKey: {
    twitter_name: string;
    twitter_avatar: string;
    twitter_username: string;
  };
}

const PartInfo = ({ dataKey }: IPartInfo) => {
  return (
    <AppCard mb={'20px'}>
      <Flex gap={'10px'} alignItems={'center'}>
        <Avatar
          w={'50px'}
          name={dataKey?.twitter_name}
          src={dataKey?.twitter_avatar}
        />
        <Flex flexDir={'column'} fontSize={18}>
          <Box fontWeight={700} fontSize={20}>
            {dataKey?.twitter_name}
          </Box>
          <Box color={'#8d91a5'}>{dataKey?.twitter_username}</Box>
        </Flex>
      </Flex>
    </AppCard>
  );
};

export default PartInfo;
